html {
  font-family: SoletoTK, sans-serif;
  box-sizing: border-box;
}

*, ::before, ::after {
  font-family: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;
  overflow-y: scroll;
}

textarea,
select,
input {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
}

ul.square {
  list-style-type: square;
  margin-left: 1em;
}

.truncate-ellipses {
  text-decoration: underline;
  cursor: pointer;
}

.grid-root-container {
  @include grid-root-container();
}

.standard-grid-item {
  @include grid-item(1, 6);
  @include grid-item-rows(1);
  @include from-width($m) {
    @include grid-item(1, 12);
  }
  @include from-width($l) {
    @include grid-item(2, 8);
  }
}

@include has-keyboard-focus();

button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
