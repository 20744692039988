@import "app/styles/basics";
@import "app/styles/font-import.scss";
@import "app/styles/essentials.scss";


body {
  background-color: $color-grey-30;
}

a {
  color: $color-anthracite-100;
  cursor: pointer;
  text-decoration: underline;
}

